import React from 'react'
import './Card.css'
import NatureIcon from "./NatureIcon"
import Dot from './Dot'
import MenuButton from './MenuButton'


function CardTopBar(props) {
  return (
    <div className="CardTopBar">
      <MenuButton wine={{wineId:props.wine.wine.id}}/>
      <Dot wine={{wineType:props.wine.wine.wineType}}/>
      <NatureIcon wine={{natureWine:props.wine.wine.natureWine}}/> 

      </div>
)}

export default CardTopBar