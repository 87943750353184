import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
const axios = require('axios');

function MenuButton(props) {

const alertFunc = () => {
    // console.log((`https://localhost:7090/api/wines/${props.wine.wineId}`))
    axios.delete((`https://localhost:7090/api/wines/${props.wine.wineId}`))
    window.location.reload(false);
}

  return (
    <div>
        <button className="top-menu-button" onClick={alertFunc}><BsThreeDotsVertical /></button>
        </div>
  )
}

export default MenuButton