import React from 'react'

function Frontpage() {
  return (
    <div>Frontpage
      <p>Hej</p>
    </div>
  )
}

export default Frontpage