import React from 'react'
import { useEffect } from 'react'
import Card from '../Card/Card'
import './Overview.css'
import CountryPicker from './CountryPicker'
import TypePicker from './TypePicker'

const axios = require('axios');

function Overview() {
  const [allWine, setAllWine] = React.useState([]);
  const [selectedWine, setSelectedWine] = React.useState([])
  var url = "https://localhost:7090/api/wines"

  const getAllWine = () => {
    axios.get(url)
    .then((response) => {
      const allWine = response.data;

      //add data to the state
      setAllWine(allWine)
      setSelectedWine(allWine)
    })
    .catch(error => console.error("Error: " + error));
  }

  const filterW = () => {
    let countryPicker = document.querySelector(".countryPicker").value;
    setSelectedWine([]);

    allWine.forEach((wine) => {
      if (wine.country === countryPicker) { 
        setSelectedWine((selectedWine) => [...selectedWine, wine])
      }
    })
  }

  

  useEffect(() => {
    getAllWine();
    
  },[]);

  return (
    <div>
      <h2>Overview</h2>
      <div className="sortBar">
      <CountryPicker wine={allWine}/>
      <TypePicker wine={allWine}/>
        <button onClick={filterW}>click</button>
        <button onClick={getAllWine}>all wine</button>
      </div>
      <div className="cardContainer">
      {selectedWine.map((wine) =>
      <Card key={wine.id} card={{wine: wine}}/>
      )}
      </div>

    </div>
  )
}

export default Overview