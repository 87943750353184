import React from 'react'
import './Card.css'

function Dot(props) {
    if (props.wine.wineType === "red") {
        return <span className="dot" style={{"backgroundColor":"red"}}><span className="tooltiptext">Rødvin</span></span>
    }
    if (props.wine.wineType === "white") {
        return <span className="dot" style={{"backgroundColor":"#ede087"}}><span className="tooltiptext">Hvidvin </span></span>
    }
    if (props.wine.wineType === "port") {
      return <span className="dot" style={{"backgroundColor":"#590900"}}><span className="tooltiptext">Portvin </span></span>
  }
  if (props.wine.wineType === "rose") {
    return <span className="dot" style={{"backgroundColor":"#F4C4BB"}}><span className="tooltiptext">Rosévin </span></span>
}
  return (
     <span className="dot" style={{"backgroundColor":"#455249"}}><span className="tooltiptext">Andet/Ukendt </span></span>
  )
}

export default Dot