import React, { useState } from "react";
import "./form.css";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../firebase";
import { ref, uploadBytes } from "firebase/storage";

const axios = require("axios");

function CreateWineForm() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [price, setPrice] = useState("");
  const [year, setYear] = useState("");
  const [rating, setRating] = useState(5);
  const [wineType, setWineType] = useState("");
  const [natureWine, setNatureWine] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);

  let hasImage = true;

  const handleChange = (event) => {
    setWineType(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    let tempID = uuidv4().toString();

    event.preventDefault();
    uploadImage(tempID);
    axios
      .post("https://localhost:7090/api/wines", {
        id: tempID,
        name: name,
        description: description,
        country: country,
        price: price,
        rating: rating,
        year: year,
        natureWine: natureWine,
        wineType: wineType,
        timeAdded: Date.now(),
        hasImage: hasImage,
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log("error");
        return error;
      });
    setTimeout(() => {
      window.location.replace("/overview");
    }, 200);
  };

  const uploadImage = (tempID) => {
    console.log("upload started");
    if (imageUpload == null) {
      hasImage = false;
      return;
    }
    const imageRef = ref(storage, `wineImages/${tempID}`);
    uploadBytes(imageRef, imageUpload).then(() => {
      console.log("Image uploaded");
    });
  };

  return (
    <div>
      <h1> Opret ny vin </h1>
      <form onSubmit={handleSubmit}>
        <label>
          Navn
          <br />
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>

        <br />

        <label>
          Beskrivelse
          <br />
          <textarea value={description} onChange={handleChangeDescription} />
        </label>

        <br />

        <label>
          Land
          <br />
          <input
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </label>

        <br />

        <label>
          Pris
          <br />
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </label>

        <br />

        <label>
          År
          <br />
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </label>

        <br />

        <label>
          {" "}
          Vintype
          <br />
          <select value={wineType} onChange={handleChange}>
            <option value="">Vælg type</option>
            <option value="red">Rødvin</option>
            <option value="white">Hvidvin</option>
            <option value="rose">Rosévin</option>
            <option value="port">Portvin</option>
          </select>
        </label>

        <br />

        <label>
          Naturvin
          <input
            type="checkbox"
            value={natureWine}
            onChange={(e) => setNatureWine(e.target.checked)}
          />
        </label>

        <br />

        <label>
          Rating - {rating}
          <br />
          <input
            type="range"
            value={rating}
            min="1"
            max="10"
            onChange={(e) => setRating(e.target.value)}
          />
        </label>

        <br />

        <input
          type="file"
          onChange={(event) => {
            setImageUpload(event.target.files[0]);
          }}
        />

        <br />

        <input type="submit" value="Gem vin" />
      </form>
    </div>
  );
}
export default CreateWineForm;
