import React from "react";
import "./Card.css";
import CardTopBar from "./CardTopBar";

function Card(props) {
  return (
    <div>
      <div className="outerContainer">
        <div className="container">
          {props.card.wine.hasImage ?  (<img
            src={"https://firebasestorage.googleapis.com/v0/b/vinapp-25003.appspot.com/o/wineImages%2F" + props.card.wine.id + "?alt=media&token=94cf4730-f254-4711-93df-9efb7ba19c68"}
            alt={props.card.wine.name}
          />) : (<img
            src={"https://firebasestorage.googleapis.com/v0/b/vinapp-25003.appspot.com/o/wineImages%2Fvin_placeholder.png?alt=media&token=94cf4730-f254-4711-93df-9efb7ba19c68"}
            alt={props.card.wine.name}
          />)}
          
            <CardTopBar wine={{ wine: props.card.wine }} />
            <div className="container__text">
              <div className="wine-header">
              <h1 >{props.card.wine.name}</h1>
              </div>
              <div className="description-text">
              <p>{props.card.wine.description}</p>
              </div>
                  </div>
              <div className="data-containers">
                  <div className="individual-data-container">
                    <h2>Pris</h2>
                    <p>{props.card.wine.price},-</p>
                  </div>
                  <div className="individual-data-container">
                    <h2>Land</h2>
                    <p>{props.card.wine.country}</p>
                  </div>
                  <div className="individual-data-container">
                    <h2>Årstal</h2>
                    <p>{props.card.wine.year}</p>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
