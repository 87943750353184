import React from 'react'
import './Card.css'

function NatureIcon(props) {
if (props.wine.natureWine) {
    return (
      <div><span role="img" aria-label="naturewine" className="natureIcon">🌿<span className="tooltiptext">Naturvin</span></span></div>
    )
}
return (<div></div>)
}

export default NatureIcon


