import React from 'react'
import {
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Frontpage from './components/Frontpage/Frontpage';
import Header from './components/Header/Header'
import Overview from './components/Overview/Overview'
import CreateWineForm from './components/CreateWine/CreateWineForm'

function App() {
  return (
    <div className="App">
      <Header/>
          <Routes>
        <Route path="/" element={<Frontpage />}></Route>
        <Route path="/overview" element={<Overview />}></Route>
        <Route path="addWine" element={<CreateWineForm />}></Route>
      </Routes>
    </div>
  );
}

export default App;
