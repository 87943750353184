import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAbxeJSxsnZi6BRD-7Lj-6RaajChydqB-I",
    authDomain: "vinapp-25003.firebaseapp.com",
    projectId: "vinapp-25003",
    storageBucket: "vinapp-25003.appspot.com",
    messagingSenderId: "1050668116102",
    appId: "1:1050668116102:web:b71508680d8255eda00907",
    measurementId: "G-4Q7XHFLYWF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);