import React from "react";


function TypePicker(props) {
  let newArrays = [];
  props.wine.forEach((type) => {
    newArrays.push(type.wineType);
  });

  var unique = newArrays.filter((v, i, a) => a.indexOf(v) === i);

  return (
    <div>
      <select className="typePicker">
        <option>Alle</option>
        {unique.map((type) => (
          <option key={type}>{type}</option>
        ))}
      </select>
    </div>
  );
}

export default TypePicker;
