import React from "react";


function CountryPicker(props) {
  let newArrays = [];
  props.wine.forEach((country) => {
    newArrays.push(country.country);
  });

  var unique = newArrays.filter((v, i, a) => a.indexOf(v) === i);

  return (
    <div>
      <select className="countryPicker">
        <option>Alle</option>
        {unique.map((country) => (
          <option key={country}>{country}</option>
        ))}
      </select>
    </div>
  );
}

export default CountryPicker;
